<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-card-title>Szczegóły pakietu</v-card-title>
              </v-col>
              <v-col class="flex_end">
                <v-btn
                  color="secondary_dark"
                  dark
                  depressed
                  tile
                  :to="'/pakiet-edytuj/'+id"
                  v-if="$store.state.user_permissions.package_update"
                >
                  Edytuj pakiet
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-text>

            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Klinet: </td>
                    <td>{{user_data.assign_user_name}}</td>
                  </tr>
                  <tr>
                    <td>Nazwa pakietu: </td>
                    <td>{{user_data.category_name}}</td>
                  </tr>
                  <tr>
                    <td>Cena: </td>
                    <td>{{user_data.price}} zł</td>
                  </tr>
                  <tr>
                    <td>Data wygaśnięcia: </td>
                    <td>{{user_data.end_data}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>


          </v-card-text>
        </v-card>

        <!-- #TODO sprawdzic czy zimny pozwala dodawac z pustym opisem(bede przesylal puste dla latwosci pozniejszego wyczyszczenia i sprawdzania tutaj bo null bedzie sypal bledy) -->
        <v-card 
          v-if="user_data.description.length"
          class="card_section"  
        >
          <v-card-title>Opis</v-card-title>
          <v-card-text>
            {{user_data.description}}
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista pakietów Klienta',
        disabled: false,
        to: '/pakiet',
      },
      {
        text: 'Szczegóły pakietu Klienta',
        disabled: true,
        to: 'pakietu',
      },
    ],

    user_data: {
      price: '123',
      description: '',
      status: false,
      date_create: new Date().toISOString().substr(0, 10),
      date_invoice: '2021-01-01',
      number_invoice: '68/2/2021'
    },
  }),
  methods: {
    
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/package/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        // Edytowanie linku
        this.breadcrumb[2].to = '/pakiet/'+resp.data.assign_user;

        console.log(resp.data);
        this.user_data = resp.data;
        this.user_data.end_data = this.global_flipDate(this.user_data.end_data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-top: 0;
}
</style>